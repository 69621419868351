import React from 'react'
import SearchBar from '../components/resuable/search/search-container'
import Layout from '../components/resuable/layout/layout'
import SEO from '../components/resuable/seo/seo'

const Search = () => {
  return (
    <Layout>
      <SEO 
        title="Search for a article!"
      />
      <SearchBar />
    </Layout>
  )
}

export default Search
