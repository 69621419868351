import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../../../../../utils/styles/styles"

const StyledSearchResult = styled(Link)`
  margin: 20px 0px;
  padding: 20px;
  flex: 1 1 100%;
  background: ${colors.darkGray};
  border: 1px solid #ccc;
  h4 {
    font-size: 20px;
    color: ${props => props.theme.foreground};
  }
`

const SearchResult = ({ title, link }) => {
  return (
    <StyledSearchResult to={`${link}`}>
      <h4>{title}</h4>
    </StyledSearchResult>
  )
}

SearchResult.defaultProps = {}

export default SearchResult
