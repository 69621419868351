import React from "react"
import styled from "styled-components"
import { useState } from "react"
import Axios from "axios"
import BlogCard from "../blog/blog-card/blog-card"
import SearchResult from "./results/search-result/search-result"
import { getSlugFromUrl } from "../../../utils/normalizers/urlToSlug"
import { ButtonType } from "../button"
import { dimensions } from "../../../utils/styles/styles"

const StyledSearchContainer = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    width: 100%;
    margin: 14rem 0 10rem;
    display: flex;
    justify-content: center;
    input {
      height: 48px;
      width: 100%;
      max-width: 480px;
      padding: 0px 12px;
      border: 1px solid #aaa;
      background: #333;
      color: #fff;
      font-size: 18px;
    }
    .search__btn {
      border-radius: 0px;
    }
  }
  .results {
    margin-bottom: 12rem;
    display: flex;
    flex-wrap: wrap;
    .status {
      padding: 20px 0px;
      text-align: center;
      color: #ddd;
      font-size: 18px;
    }
  }
`

const SearchContainer = props => {
  const [data, setData] = useState([])
  const [query, setQuery] = useState("")
  const [status, setStatus] = useState("PRE__FETCH")
  let statusMsg = '';
  const handleSubmit = async e => {
    e.preventDefault()
    setStatus("FETCHING")
    try {
      const res = await Axios.get(
        `https://blog.leadmirror.com/wp-json/wp/v2/search?search=${query}&subtype=post`
      )
      setStatus("FETCH__SUCCESS")
      // IF no results found
      if (res.data.length === 0) {
        setStatus("FETCH__404")
      }
      setData(res.data)
    } catch (error) {
      setStatus("FETCH__ERROR")
    }
  }

  // Statuses
  if(status === 'PRE__FETCH') statusMsg = null
  if(status === 'FETCHING') statusMsg = <p className="status">Fetching...</p>
  if(status === 'FETCH__SUCCESS') statusMsg = <p className="status">The following results were found</p>
  if(status === 'FETCH__ERROR') statusMsg = <p className="status">Server problem. Please try again later.</p>
  if(status === 'FETCH__404') statusMsg = <p className="status">Not info found</p>

  return (
    <>
      <StyledSearchContainer>
        <form onSubmit={handleSubmit}>
          <input
            type="search"
            name="search"
            placeholder="Search for an article..."
            id="search"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <ButtonType.Base className="search__btn">Submit</ButtonType.Base>
        </form>
        <div className="results">
          {statusMsg}
          {data.length !== 0 &&
            data.map((post, index) => {
              const link = getSlugFromUrl(post.url)
              return (
                <SearchResult
                  key={index}
                  title={post.title}
                  link={`/a2i-world/${link}`}
                />
              )
            })}
        </div>
      </StyledSearchContainer>
    </>
  )
}

SearchContainer.defaultProps = {}

export default SearchContainer
